<template>
  <button
    v-tooltip.right="$t('FEEDBACK.TITLE')"
    class="text-slate-700 dark:text-slate-100 w-11 h-11 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
    @click="$emit('click')"
  >
    <fluent-icon icon="comment-add" class="w-6 h-6" />
    <span class="sr-only">{{ $t('FEEDBACK.TITLE') }}</span>
  </button>
</template>
